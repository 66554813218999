import React from "react";
import StoresQuickFilterButton from "./StoresQuickFilterButton";
import { TruckIcon as TruckIconSolid } from "@heroicons/react/solid";
import { TruckIcon as TruckIconOutline } from "@heroicons/react/outline";

function StoresDeliveryFilter({
	isFilterActive,
	className,
	setIsDrawerOpen,
	isDrawerOpen,
	disabled = false,
}) {
	const handleFilterClick = () => {
		setIsDrawerOpen(!isDrawerOpen);
	};

	return (
		<StoresQuickFilterButton
			isActive={isFilterActive}
			className={className}
			label="משלוח"
			activeIcon={<TruckIconSolid />}
			inactiveIcon={<TruckIconOutline />}
			onClick={handleFilterClick}
			disabled={disabled}
		/>
	);
}

export default StoresDeliveryFilter;

import { StoresUpdateTime } from "components/stores/StoresUpdateTime";
import { FiltersBar } from "components/tables/FiltersBar";
import { DebounceInput } from "components/tables/server-table/DebounceInput";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { classNames, toastMessage } from "util/common";
import StoreDidNotUpdateMessage from "components/ui/StoreDidNotUpdateMessage";
import FilterTags from "components/filters/FilterTags";
import MapTableIcon from "components/maps/utils/MapTableIcon";
import { useIsInIframe } from "components/hooks/useIsInIframe";
import { CacheContext } from "components/contexts/CacheContext";
import FiltersButton from "components/ui/FiltersButton";
import StoresQuickFilter from "components/ui/StoresQuickFilter";
import useUserAddresses from "components/hooks/useUserAddresses";
import StoresDeliveryFilter from "components/ui/StoresDeliveryFilter";
import DeliveryDrawer from "components/ui/drawers/DeliveryDrawer";
import LocationDrawer from "components/ui/drawers/LocationDrawer";
import StoresLocationFilter from "components/ui/StoresLocationFilter";
import LayoutButton from "components/ui/LayoutButton";

const StoresTableContainer = ({
	prependStoreContent,
	hideOnScroll,
	prependContent,
	tbl,
	title,
	itemsCountLabel,
	subtitle,
	prependStoreSortByContent,
	searchable,
	onSearchBounce,
	hasFilters,
	showFilters,
	setShowFilters,
	filters,
	filterValues,
	filtersChanged,
	subContent,
	is48HoursPast,
	isHomeWidget,
	isWithDiscountsFilter,
	isWithShippingFilter,
	isWithOpenFilter,
	isWithLocationButton,
	customButtons,
	children,
	isStoresTable,
	isShabbatPage,
	searchQuery,
	setIsTableView,
	isTableView,
	withMap = true,
	searchPlaceholder,
}) => {
	const isInIframe = useIsInIframe();
	const { allCities } = useContext(CacheContext);
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [isLocationDrawerOpen, setIsLocationDrawerOpen] = useState(false);

	const { userAddresses = [] } = useUserAddresses();

	const formattedAddresses = userAddresses.map(address => ({
		label: address.city_heb_name,
		value: address.city_id,
		isHomeAddress: true,
	}));

	const formattedAllCities = allCities.map(c => ({
		label: c.heb_name,
		value: c.id,
	}));

	const initialOptions = [...formattedAddresses];

	if (formattedAddresses.length > 0) {
		initialOptions.push({
			id: "-1",
			label: "------------",
			value: undefined,
			isDisabled: true,
		});
	}

	const options = [...initialOptions, ...formattedAllCities].filter(
		(value, index, self) =>
			index ===
			self.findIndex(t => t.label === value.label && t.value === value.value),
	);

	useEffect(() => {
		if (
			!filterValues.some(
				f => f.property === "shipping_city_restrictions__city_id",
			) &&
			filterValues.some(f => f.property === "today_or_tomorrow_shipping")
		) {
			const newValues = filterValues.filter(
				f => f.property !== "today_or_tomorrow_shipping",
			);
			filtersChanged(newValues);
		}
	}, [
		filterValues.some(
			f => f.property === "shipping_city_restrictions__city_id",
		),
	]);

	const deliveryFilter = useMemo(
		() => [
			{
				name: "עיר למשלוח",
				property: "shipping_city_restrictions__city_id",
				placeholder: "ערים",
				isSearchableFilter: true,
				type: "select",
				isMulti: true,
				condition: "in",
				options: options,
			},
		],
		[options],
	);

	return (
		<div className="relative">
			{/* DRAWER OVERLAY */}
			{(isDrawerOpen || isLocationDrawerOpen) && (
				<div
					onClick={() => {
						setIsDrawerOpen(false);
						setIsLocationDrawerOpen(false);
					}}
					className="absolute  z-40 inset-0 w-full h-full bg-jane-200 bg-opacity-70 backdrop-blur-[2px]"
				/>
			)}
			<div className="absolute rounded-md inset-0 w-full h-full overflow-hidden">
				<DeliveryDrawer
					deliveryFilter={deliveryFilter}
					filterValues={filterValues}
					filtersChanged={filtersChanged}
					isDrawerOpen={isDrawerOpen}
					setIsDrawerOpen={setIsDrawerOpen}
				/>
				<LocationDrawer
					isDrawerOpen={isLocationDrawerOpen}
					withCurrentData={tbl?.addtionalParams}
					setIsDrawerOpen={setIsLocationDrawerOpen}
					tbl={tbl}
				/>
			</div>
			<div className="bg-white shadow rounded-md">
				{prependStoreContent}
				{hideOnScroll && prependContent}
				<div
					className={classNames(
						"bg-white rounded-t-md",
						`sticky z-30 ${
							isHomeWidget && isInIframe
								? "top-[40px]"
								: isHomeWidget || isInIframe
								? "top-0"
								: "top-[55px] lg:top-[60px]"
						}`,
					)}
				>
					{!hideOnScroll && prependContent}

					<div
						className={classNames(
							"flex p-2 sm:items-center items-start sm:flex-row justify-between flex-wrap w-full gap-2",
						)}
					>
						{!!subtitle && (
							<p className="mt-1 text-sm text-gray-500">{subtitle}</p>
						)}
						{(isHomeWidget || isInIframe) && (
							<div className="flex w-full">
								<h2 className="flex items-center text-base font-semibold text-gray-800 ml-2">
									{!!tbl.ready && title}
									{!!tbl.ready && (
										<span className="mr-1.5 text-gray-500 font-medium text-sm">
											{itemsCountLabel}
										</span>
									)}
									<br />
								</h2>
								{tbl?.lastUpdateAt ? (
									<div className="flex items-center">
										<StoresUpdateTime lastUpdateAt={tbl.lastUpdateAt} />
									</div>
								) : null}
							</div>
						)}
						<div className="flex items-center flex-row justify-between w-full">
							<div className="grid grid-cols-4 w-full gap-2 items-center md:grid-cols-6">
								{!!searchable ? (
									<DebounceInput
										placeholder={searchPlaceholder}
										onBounce={onSearchBounce}
										disabled={!tbl.ready}
										className={`w-full col-span-2 md:min-w-[150px] grow h-full`}
										searchQuery={searchQuery}
									/>
								) : (
									<span />
								)}
								<span className="flex h-full w-full justify-end col-start-4 col-end-auto md:col-start-6 gap-2 items-center">
									<FiltersButton
										toggleFilters={() => setShowFilters(!showFilters)}
										showFilters={showFilters}
										className="h-full w-full md:w-auto"
									/>
									{!!tbl.ready && withMap && (
										<MapTableIcon
											isTableView={isTableView}
											setIsTableView={setIsTableView}
										/>
									)}
								</span>
							</div>
							<div className="flex">
								{!!prependStoreSortByContent && (
									<span className="z-40">{prependStoreSortByContent}</span>
								)}
							</div>
							<LayoutButton
								layoutOptions={tbl.layoutOptions}
								layout={tbl.layout}
								changeLayout={tbl.changeLayout}
								loading={!tbl.ready}
							/>
						</div>
						{showFilters && (
							<div className="flex flex-col gap-2 w-full">
								<div className="grid grid-cols-4 md:grid-cols-6 gap-2 justify-start lg:justify-end w-full">
									{hasFilters && (
										<span className="hidden md:block col-span-2 w-full">
											<FiltersBar
												filters={filters}
												values={filterValues}
												onChange={filtersChanged}
												isSingleProductContainer={true}
												disabled={!tbl.ready || tbl.loading}
											/>
										</span>
									)}

									{!!isWithLocationButton && (
										<StoresLocationFilter
											isDrawerOpen={isLocationDrawerOpen}
											setIsDrawerOpen={setIsLocationDrawerOpen}
											isFilterActive={
												tbl?.addtionalParams?.latitude ||
												tbl?.addtionalParams?.longitude
											}
											tbl={tbl}
											withCurrentData={tbl?.addtionalParams}
											className={classNames("w-full", !isTableView && "hidden")}
											disabled={!tbl.ready || tbl.loading}
										/>
									)}
									{!!customButtons ? customButtons : null}
									{isWithOpenFilter && (
										<StoresQuickFilter
											tbl={tbl}
											filterType="open"
											className="w-full"
											disabled={!tbl.ready || tbl.loading}
										/>
									)}
									{isWithShippingFilter && (
										<StoresDeliveryFilter
											isDrawerOpen={isDrawerOpen}
											setIsDrawerOpen={setIsDrawerOpen}
											isFilterActive={filterValues.some(
												f =>
													f.property === "shipping_city_restrictions__city_id",
											)}
											disabled={!tbl.ready || tbl.loading}
										/>
									)}
									{isWithDiscountsFilter && (
										<StoresQuickFilter
											tbl={tbl}
											filterType="discount"
											className="w-full"
											disabled={!tbl.ready || tbl.loading}
										/>
									)}
								</div>
								<span className="block md:hidden w-full">
									{hasFilters && showFilters && (
										<FiltersBar
											filters={filters}
											values={filterValues}
											onChange={filtersChanged}
											disabled={!tbl.ready || tbl.loading}
										/>
									)}
								</span>
							</div>
						)}
					</div>
					{hasFilters && showFilters && !isDrawerOpen && (
						<FilterTags
							filterValues={filterValues}
							isQuickFilter={false}
							isSingleStore={true}
							onFiltersChanged={filtersChanged}
							disabled={!tbl.ready || tbl.loading}
						/>
					)}
					{subContent && <div className="pb-2"> {subContent}</div>}
				</div>
				<div className="relative p-1">
					{!!is48HoursPast ? <StoreDidNotUpdateMessage /> : children}
				</div>
			</div>
		</div>
	);
};

export default StoresTableContainer;

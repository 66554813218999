import React, { useState } from "react";
import { ModalDialog } from "../ModalDialog";
import { Button } from "components/form/Button";
import { ArrowLeftIcon } from "@heroicons/react/solid";

function GoToJaneModal({ productId, ...props }) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const redirectToProductPage = () => {
		const rowParams = { modal: String(true) };
		const queryParams = new URLSearchParams(rowParams);
		setIsModalOpen(false);
		window.open(`/products/${productId}?${queryParams}`, "_blank");
	};
	return (
		<ModalDialog
			activator={props?.activator || defaultActivator}
			isOpen={isModalOpen}
			title="יצירת התראה"
			showSubmitButton={false}
			showCancelButton={true}
			cancelBtnText="סגירה"
			closeOnClickOutside={true}
		>
			<div className="flex flex-col justify-center items-center gap-4">
				<img src="/jane-logo.svg" alt="Jane" className="w-32" />
				<div>ליצירת התראה תועברו לאתר ג&apos;יין</div>
				<Button
					primary
					className="w-full gap-1 flex justify-center max-w-[300px]"
					onClick={() => {
						redirectToProductPage();
					}}
				>
					<span>יצירת התראה ג&apos;יין</span>
					<ArrowLeftIcon className="w-3 h-3" />
				</Button>
			</div>
		</ModalDialog>
	);
}

export default GoToJaneModal;

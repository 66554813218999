import { MapIcon, ViewListIcon } from "@heroicons/react/outline";
import React from "react";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { LAYOUT } from "util/common";

function LayoutButton({
	layoutOptions = [],
	layout,
	changeLayout,
	loading,
	className,
}) {
	const layoutIcons = {
		[LAYOUT.LIST]: <ViewListIcon className="h-full w-full" />,
		[LAYOUT.GRID]: <BsFillGrid3X3GapFill className="h-full w-full" />,
		[LAYOUT.MAP]: <MapIcon className="h-full w-full" />,
	};
	const nextLayout =
		layout === layoutOptions[0] ? layoutOptions[1] : layoutOptions[0];

	if (layoutOptions?.length === 1 || layoutOptions?.length === 0) return;

	if (loading) {
		return (
			<div className="min-w-[24px] h-[24px] bg-jane-200 rounded-md animate-pulse" />
		);
	}

	return (
		<button
			id={`__table_layout_change__${layout}`}
			onClick={() => changeLayout()}
			className={"h-[24px] w-[24px] p-0 text-jane-400 " + className}
		>
			{layoutIcons[nextLayout]}
		</button>
	);
}

export default LayoutButton;

import { StoresUpdateTime } from "components/stores/StoresUpdateTime";
import React from "react";
import { classNames, useIsTabletMode } from "util/common";
import { useIsInIframe } from "components/hooks/useIsInIframe";
import TableFiltersWrapper from "components/filters/TableFiltersWrapper";
import StoreDidNotUpdateMessage from "components/ui/StoreDidNotUpdateMessage";
import FilterTags from "components/filters/FilterTags";
import FiltersButton from "components/ui/FiltersButton";
import { DebounceInput } from "components/tables/server-table/DebounceInput";
import StoresQuickFilter from "components/ui/StoresQuickFilter";
import LayoutButton from "components/ui/LayoutButton";

const SingleStoreContainer = ({
	prependStoreContent,
	hideOnScroll,
	prependContent,
	tbl,
	title,
	itemsCountLabel,
	subtitle,
	prependStoreSortByContent,
	searchable,
	onSearchBounce,
	hasFilters,
	onShowFiltersClicked,
	showFilters,
	filters,
	filterValues,
	filtersChanged,
	subContent,
	is48HoursPast,
	isHomeWidget,
	isWithDiscountsFilter,
	customButtons,
	changeLayout,
	isOrdersEnabled,
	children,
	searchQuery,
	setShowFilters,
	searchPlaceholder,
}) => {
	const isInIframe = useIsInIframe();
	const isTabletMode = useIsTabletMode();
	return (
		<div className="rounded-md ">
			{prependStoreContent}
			{hideOnScroll && prependContent}
			<div>
				<div
					className={classNames(
						"bg-white rounded-md shadow-sm sticky z-20",
						isInIframe || (isHomeWidget && !isOrdersEnabled)
							? "top-[0px]"
							: isHomeWidget && isOrdersEnabled
							? "top-[55px]"
							: `top-[55px] lg:top-[64px]`,
					)}
				>
					{!hideOnScroll && prependContent}
					<div
						className={`px-1 py-2 flex flex-col gap-2 sm:flex-row sm:items-center sm:justify-between`}
					>
						<div
							className={`flex items-center sm:items-start flex-row sm:flex-col justify-between`}
						>
							<div className={`flex w-full gap-4`}>
								<h2 className="flex gap-1 items-center text-base font-semibold text-gray-800">
									{!!tbl.ready && title}
									{!!tbl.ready && (
										<span className="text-gray-500 font-medium text-sm">
											{itemsCountLabel}
										</span>
									)}
								</h2>
								{tbl?.lastUpdateAt ? (
									<div className="flex items-center">
										<StoresUpdateTime lastUpdateAt={tbl.lastUpdateAt} />
									</div>
								) : null}

								<div className="flex">
									{!!subtitle && (
										<p className="mt-1 text-sm text-gray-500">{subtitle}</p>
									)}
									{!!prependStoreSortByContent && (
										<span className="z-40">{prependStoreSortByContent}</span>
									)}
								</div>
							</div>
							<LayoutButton
								layoutOptions={tbl.layoutOptions}
								layout={tbl.layout}
								changeLayout={tbl.changeLayout}
								loading={!tbl.ready}
								className="sm:hidden"
							/>
						</div>
						<div
							className={`flex items-center sm:mt-0 gap-2 md:flex-nowrap w-full sm:justify-end`}
						>
							{!!searchable && !!tbl.ready && (
								<DebounceInput
									placeholder={searchPlaceholder}
									onBounce={onSearchBounce}
									searchQuery={searchQuery}
									className={classNames(
										"w-[225px] sm:w-[165px] ",
										isInIframe && "w-[185px]",
										isTabletMode && "!h-10 !w-full !text-base",
									)}
								/>
							)}
							{!!hasFilters && (
								<FiltersButton
									showFilters={showFilters}
									toggleFilters={() => setShowFilters(!showFilters)}
									filterValues={filterValues}
									className={classNames(
										isTabletMode ? " h-10 !px-2 !text-base " : "h-[30px]",
									)}
								/>
							)}
							{!!isWithDiscountsFilter && (
								<StoresQuickFilter tbl={tbl} filterType="discount" />
							)}
							<LayoutButton
								layoutOptions={tbl.layoutOptions}
								layout={tbl.layout}
								changeLayout={tbl.changeLayout}
								loading={!tbl.ready}
								className="hidden sm:flex"
							/>

							{!!customButtons ? customButtons : null}
						</div>
					</div>
					<div>{subContent}</div>
					{!!hasFilters && isTabletMode && (
						<FilterTags
							filterValues={filterValues}
							isQuickFilter={false}
							isSingleStore={true}
							onFiltersChanged={filtersChanged}
						/>
					)}
				</div>
				{!!hasFilters && !isTabletMode && (
					<div className="block">
						<FilterTags
							filterValues={filterValues}
							isQuickFilter={false}
							isSingleStore={true}
							onFiltersChanged={filtersChanged}
						/>
					</div>
				)}
				<div className="flex mt-2 lmd:gap-2">
					{!!hasFilters && (
						<TableFiltersWrapper
							filters={filters}
							values={filterValues}
							onChange={filtersChanged}
							isOpen={showFilters}
							setIsOpen={setShowFilters}
							isSingleStore={true}
						/>
					)}

					<div className="w-full">
						{!!is48HoursPast ? <StoreDidNotUpdateMessage /> : children}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SingleStoreContainer;

import { XIcon } from "@heroicons/react/solid";
import React from "react";

function FilterTag({ removeFilter, label, disabled }) {
	return (
		<div className="flex items-center gap-1 border-jane-200 bg-jane-500 rounded-full h-6 pl-2 pr-1">
			<XIcon
				className="h-4 w-4 text-white cursor-pointer hover:bg-jane-600 rounded-full"
				onClick={() => {
					if (disabled) return;
					removeFilter();
				}}
			/>
			<p className="text-[13px] text-white whitespace-nowrap">{label}</p>
		</div>
	);
}

export default FilterTag;

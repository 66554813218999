export function EmptyPlaceholder({
	icon,
	text = "אין פריטים להצגה.",
	onClick = () => false,
}) {
	return (
		<button
			onClick={onClick}
			type="button"
			className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-jane-500"
		>
			<svg
				className="mx-auto h-12 w-12 text-gray-400"
				xmlns="http://www.w3.org/2000/svg"
				stroke="currentColor"
				fill="none"
				viewBox="0 0 48 48"
				aria-hidden="true"
			>
				{icon}
			</svg>
			<span className="mt-2 block text-sm font-medium text-jane-700">
				{text}
			</span>
		</button>
	);
}

import React, { useEffect, useState } from "react";
import TableContainerDrawer from "../TableContainerDrawer";
import { TruckIcon } from "@heroicons/react/outline";
import { Button } from "components/form/Button";
import { FiltersBar } from "components/tables/FiltersBar";
import { Toggle } from "components/form/Toggle";
import FilterTags from "components/filters/FilterTags";

const DeliveryDrawer = ({
	isDrawerOpen,
	setIsDrawerOpen,
	filterValues,
	filtersChanged,
	deliveryFilter,
	todayOrTomorrowProperty = "today_or_tomorrow_shipping",
	shippingProperty = "shipping_city_restrictions__city_id",
}) => {
	const [filtersToApply, setFiltersToApply] = useState(filterValues);

	useEffect(() => {
		setFiltersToApply(filterValues);
	}, [filterValues]);

	const handleToResults = () => {
		setIsDrawerOpen(false);
		if (filtersToApply.length) {
			filtersChanged(filtersToApply);
		}
	};

	const handleSameDayToggle = e => {
		let newValues = filtersToApply;
		if (e.target.checked) {
			if (!filtersToApply.some(f => f.property === todayOrTomorrowProperty)) {
				newValues = [
					...filtersToApply,
					{
						property: todayOrTomorrowProperty,
						value: ["True"],
						labels: ["היום / מחר"],
						condition: "in",
					},
				];
			}
		} else {
			newValues = filtersToApply.filter(
				f => f.property !== todayOrTomorrowProperty,
			);
		}

		setFiltersToApply(newValues);
	};

	return (
		<TableContainerDrawer
			isOpen={isDrawerOpen}
			onClose={() => setIsDrawerOpen(false)}
			className="z-40 h-full"
		>
			<div className="w-full p-2 flex justify-between  gap-2 items-center text-jane-500">
				<p className="font-semibold text-lg flex items-center gap-2">
					<TruckIcon className="h-5 w-5" />
					משלוחים
				</p>

				<Button primary onClick={() => handleToResults()}>
					<span>לתוצאות</span>
				</Button>
			</div>
			<div className="flex flex-col gap-2 p-2">
				<div className="w-full">
					<p>בחירת עיר למשלוח</p>
					<FiltersBar
						filters={deliveryFilter}
						values={filtersToApply}
						onChange={setFiltersToApply}
						autoFocus={true}
					/>
				</div>
				{filtersToApply.some(f => f.property === shippingProperty) && (
					<span className="flex gap-2 w-full items-center">
						<Toggle
							onToggle={handleSameDayToggle}
							checked={filtersToApply.some(
								f =>
									f.property === "store__today_or_tomorrow_shipping" ||
									f.property === "today_or_tomorrow_shipping",
							)}
						/>
						משלוח להיום/מחר
					</span>
				)}
			</div>
			<FilterTags
				filterValues={filtersToApply}
				isQuickFilter={false}
				isSingleStore={true}
				onFiltersChanged={setFiltersToApply}
			/>
		</TableContainerDrawer>
	);
};

export default DeliveryDrawer;

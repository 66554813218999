import React, { useEffect, useState } from "react";
import { classNames } from "util/common";

export function StoresUpdateTime({ lastUpdateAt }) {
	const [store, setStore] = useState(null);
	const [color, setColor] = useState("bg-green-900");

	useEffect(() => {
		updateStores();
	}, [lastUpdateAt]);

	const updateStores = async () => {
		if (!!lastUpdateAt) {
			const date = new Date(lastUpdateAt);
			const formatedTimeStamp = formatTime(date);
			setStore(formatedTimeStamp);
		}
	};

	const getColor = (minutes, hours) => {
		if (hours > 5) {
			return "bg-green-800";
		} else if (hours === 4 && minutes === 20) {
			return "bg-red-300 animate-bounce";
		} else if (hours > 3) {
			return "bg-green-700";
		} else if (hours > 2) {
			return "bg-green-600";
		} else if (hours > 1) {
			return "bg-green-600";
		} else if (hours > 0) {
			return "bg-green-500";
		} else if (minutes > 45) {
			return "bg-green-500";
		} else if (minutes > 30) {
			return "bg-green-400";
		} else if (minutes > 15) {
			return "bg-green-400";
		} else if (minutes > 5) {
			return "bg-green-300";
		} else if (minutes >= 1) {
			return "bg-green-300";
		}
		return "bg-green-900";
	};

	const formatTime = timestamp => {
		const timeDifference = Date.now() - new Date(timestamp);
		const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
		const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
		const minutes = Math.floor((timeDifference / 1000 / 60) % 60);
		const seconds = Math.floor((timeDifference / 1000) % 60);
		setColor(getColor(minutes, hours));
		if (days > 0) {
			return `${days} ימים`;
		} else if (hours === 1) {
			return `שעה `;
		} else if (hours > 0) {
			return `${hours} שעות`;
		} else if (minutes === 1) {
			return `דקה`;
		} else if (minutes > 0) {
			return `${minutes} דקות`;
		} else if (seconds > 0) {
			return `${seconds} שניות`;
		}
	};

	return (
		<span className="text-xs mr-0.5 font-normal">
			<span className="text-jane-600">עודכן לפני:</span>
			&nbsp;&nbsp;
			<span className="text-jane-900 inline-block">
				{" "}
				<span
					className={classNames(
						color,
						"w-[9px] h-[9px] inline-block ml-1 animate-pulse rounded-full opacity-75",
					)}
				></span>
				{store}
			</span>
		</span>
	);
}

import React, { useContext, useEffect, useState } from "react";
import TableContainerDrawer from "../TableContainerDrawer";
import { MdMyLocation } from "react-icons/md";
import { LocationMarkerIcon, XIcon } from "@heroicons/react/outline";
import Select from "react-select";
import { Button } from "components/form/Button";
import { CacheContext } from "components/contexts/CacheContext";
import { selectStyles } from "util/types";
import { toastMessage } from "util/common";

const LocationDrawer = ({
	isDrawerOpen,
	setIsDrawerOpen,
	withCurrentData,
	tbl,
}) => {
	const cache = useContext(CacheContext);
	const [showCitySelect, setShowCitySelect] = useState(null);

	useEffect(() => {
		if (!isDrawerOpen) {
			setShowCitySelect(false);
		}
	}, [isDrawerOpen]);

	const requestLocation = () => {
		navigator?.permissions?.query({ name: "geolocation" }).then(result => {
			navigator.geolocation.getCurrentPosition(
				position => {
					if (position.coords) {
						const params = {
							latitude: position.coords.latitude,
							longitude: position.coords.longitude,
						};
						if (withCurrentData) {
							params = {
								...withCurrentData,
								latitude: position.coords.latitude,
								longitude: position.coords.longitude,
							};
						}
						tbl.changeAddtionalParams(params);
						setIsDrawerOpen(false);
					}
				},
				error => {
					toastMessage("לא ניתן לקבל מיקום, יש לאפשר שיתוף מיקום", "error");
					console.error(error);
				},
			);
		});
	};

	const manualLocation = selectedCity => {
		const params = {
			latitude: selectedCity.latitude,
			longitude: selectedCity.longitude,
		};
		if (withCurrentData) {
			params = {
				...withCurrentData,
				latitude: selectedCity.latitude,
				longitude: selectedCity.longitude,
			};
		}
		tbl.changeAddtionalParams(params);
		setIsDrawerOpen(false);
		setShowCitySelect(false);
	};

	return (
		<TableContainerDrawer
			isOpen={isDrawerOpen}
			onClose={() => {
				setIsDrawerOpen(false);
				setShowCitySelect(false);
			}}
			className="z-40"
		>
			<div className="flex justify-between p-2 text-jane-500 font-semibold relative">
				<p className="text-lg flex items-center gap-2">
					<LocationMarkerIcon className="h-5 w-5" />
					קרוב אליי
				</p>
				<XIcon
					className="h-8 w-8 p-1 rounded-md border cursor-pointer transition-all hover:bg-jane-100"
					onClick={() => {
						setIsDrawerOpen(false);
						setShowCitySelect(false);
					}}
				/>
				{!showCitySelect && (
					<span className="absolute -bottom-2 text-sm">
						יש לאפשר שירותי מיקום
					</span>
				)}
			</div>
			<div className="w-full p-2 flex flex-col  gap-2  text-jane-500">
				{showCitySelect ? (
					<div>
						<p>בחר עיר:</p>
						<Select
							className=""
							isClearable={false}
							isSearchable={true}
							styles={selectStyles}
							options={cache.allCities.map(c => ({
								label: c.heb_name,
								id: c.id,
								district: c.district,
								latitude: +c.latitude,
								longitude: +c.longitude,
							}))}
							onChange={city => {
								manualLocation(city);
							}}
							placeholder={"בחירה..."}
							noOptionsMessage={() => "לא נמצאו פריטים."}
						/>
					</div>
				) : (
					<div className="flex gap-2 items-center justify-center w-full">
						<Button
							type="button"
							primary={true}
							onClick={() => {
								setShowCitySelect(true);
							}}
						>
							לפי כתובת
						</Button>
						<Button
							type="button"
							primary={true}
							onClick={() => {
								requestLocation();
							}}
						>
							לפי מיקום
						</Button>
					</div>
				)}
			</div>
		</TableContainerDrawer>
	);
};

export default LocationDrawer;

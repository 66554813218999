import React from "react";

function StoreDidNotUpdateMessage() {
	return (
		<div className="my-2 text-right text-sm text-gray-500 ">
			בית המרקחת לא עדכן מעל 72 שעות ולכן המלאי אינו מוצג.
		</div>
	);
}

export default StoreDidNotUpdateMessage;

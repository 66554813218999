import { Disclosure, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { classNames } from "util/common";

function FilterDisclosure({
	label = "",
	options = [],
	values = [],
	filtersOpen,
	disabled,
	onChange,
	isSearchableFilter = false,
	defaultOpen = false,
	isMulti = false,
	showNum = "",
}) {
	const [searchTerm, setSearchTerm] = useState("");
	const disclosureRef = useRef(null);
	const handleCheck = e => {
		const option = options.find(
			opt => String(opt.value) === String(e.target.name),
		);
		if (e.target.checked) {
			onChange([...values, option.value]);
		} else {
			onChange(values.filter(val => val !== option.value));
		}
	};

	function sortByPropertyAndAlphabet(prop) {
		return function (a, b) {
			const aValue = a[prop] !== undefined ? a[prop] : 0;
			const bValue = b[prop] !== undefined ? b[prop] : 0;

			// Prioritize options with prop not undefined and greater than 0
			if (aValue > 0) {
				if (bValue > 0) {
					// Both have prop > 0, sort alphabetically
					return a.label.localeCompare(b.label);
				} else {
					// Only a has prop > 0, prioritize a
					return -1;
				}
			} else {
				if (bValue > 0) {
					// Only b has prop > 0, prioritize b
					return 1;
				} else {
					// Both don't have prop > 0, sort alphabetically
					return a.label.localeCompare(b.label);
				}
			}
		};
	}

	const filteredOptions = options.filter(
		option =>
			option?.label?.toLowerCase().includes(searchTerm.toLowerCase()) ||
			option?.eng_name?.toLowerCase().includes(searchTerm.toLowerCase()),
	);

	if (!!showNum) {
		filteredOptions.sort(sortByPropertyAndAlphabet(showNum));
	}

	useEffect(() => {
		disclosureRef.current?.getAttribute("data-open") === "true" &&
			!defaultOpen &&
			disclosureRef.current?.click();
	}, [filtersOpen]);

	return (
		<Disclosure defaultOpen={defaultOpen}>
			{({ open }) => {
				return (
					<>
						<Disclosure.Button
							ref={disclosureRef}
							data-open={open}
							className="py-2 w-full font-[600] flex justify-between items-center"
						>
							<span>
								{label}
								{values.length !== 0 && (
									<span className="text-jane-400"> ({values.length})</span>
								)}
							</span>
							<ChevronDownIcon
								className={classNames(
									"h-4 w-4 transition-transform",
									open ? "rotate-180" : "rotate-0",
								)}
							/>
						</Disclosure.Button>

						<Disclosure.Panel className="text-gray-500 p-2 ">
							{isSearchableFilter && (
								<input
									type="search"
									placeholder="חיפוש..."
									value={searchTerm}
									onChange={e => setSearchTerm(e.target.value)}
									onFocus={e => {
										e.preventDefault();
										e.stopPropagation();
									}}
									onBlur={e => {
										e.preventDefault();
										e.stopPropagation();
									}}
									className="block w-full  border-gray-300 rounded-md text-xs h-[30px] focus:ring-jane-500 focus:border-jane-500 mb-2"
								/>
							)}
							<div className="max-h-80 overflow-y-auto flex flex-col gap-2 pb-2 custom-scroll relative">
								{filteredOptions.map((option, index) => (
									<div
										key={index}
										className="flex gap-2 items-center  cursor-pointer  "
									>
										<input
											type="checkbox"
											name={option.value}
											id={option.value}
											className="cursor-pointer focus:ring-0"
											disabled={disabled}
											onChange={handleCheck}
											checked={values.includes(option.value)}
										/>
										<label
											htmlFor={option.value}
											className="cursor-pointer w-full flex gap-1 items-center"
										>
											{option.img_url && (
												<img src={option.img_url} className="w-4 h-auto" />
											)}
											<span>
												{option.label}
												{!!showNum && (
													<span className="text-jane-400 text-xs mr-0.5 font-semibold">
														{!!option[showNum]
															? "(" + option[showNum] + ")"
															: "(0)"}
													</span>
												)}
											</span>
										</label>
									</div>
								))}
							</div>
						</Disclosure.Panel>
					</>
				);
			}}
		</Disclosure>
	);
}

export default FilterDisclosure;

import {
	ArrowNarrowLeftIcon,
	ArrowNarrowRightIcon,
} from "@heroicons/react/solid";
import React from "react";
import { classNames } from "util/common";

const pageSizes = [10, 25, 50, 75, 100];

export const TablePagination = ({
	pageCount,
	pageIndex,
	pageSize,
	canPreviousPage,
	canNextPage,
	disabled = false,
	onNextClicked = () => {},
	onPrevClicked = () => {},
	onPageNumClicked = () => {},
	onPageSizeChanged = ps => {},
}) => {
	const getItems = () => {
		const ret = [];
		const pageNum = pageIndex + 1;
		const stretchToFirst = pageNum > 2;
		const stretchToLast = pageCount - pageNum > 1;
		if (stretchToFirst) {
			ret.push(1);
			ret.push({ spacer: true });
		}
		if (pageNum - 1 > 0) {
			ret.push(pageNum - 1);
		}
		ret.push(pageNum);
		if (pageNum + 1 <= pageCount) {
			ret.push(pageNum + 1);
		}
		if (stretchToLast) {
			ret.push({ spacer: true });
			ret.push(pageCount);
		}
		return ret;
	};

	const pageSizeChanged = ps => {
		if (!!disabled || !ps || !!isNaN(ps)) {
			return;
		}
		const val = parseInt(ps);
		if (pageSizes.indexOf(val) === -1 || val === pageSize) {
			return;
		}
		onPageSizeChanged(val);
	};

	if (!!disabled) {
		canPreviousPage = false;
		canNextPage = false;
	}

	return (
		<div>
			<nav className="bg-white border-t border-gray-200 px-4 pb-4 flex items-center justify-between sm:px-0 select-none">
				<div className="-mt-px w-0 flex-1 flex">
					<div
						onClick={() => !!canPreviousPage && onPrevClicked()}
						className={classNames(
							"border-t-2 border-transparent pt-4 ps-2 inline-flex items-center text-sm font-medium text-gray-500",
							!!canPreviousPage
								? "cursor-pointer hover:text-gray-700 hover:border-gray-300"
								: "opacity-40",
						)}
					>
						<ArrowNarrowRightIcon
							className="me-3 h-5 w-5 text-gray-400"
							aria-hidden="true"
						/>
						הקודם
					</div>
				</div>
				<div className="hidden md:-mt-px md:flex">
					{getItems().map((pageNum, idx) =>
						typeof pageNum === "object" && !!pageNum.spacer ? (
							<div key={idx} className="pt-4 px-2 inline-flex text-gray-400">
								...
							</div>
						) : (
							<div
								key={idx}
								onClick={() => onPageNumClicked(pageNum)}
								className={classNames(
									"border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium cursor-pointer",
									pageNum === pageIndex + 1
										? "border-jane-500 text-jane-600"
										: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
								)}
							>
								{pageNum}
							</div>
						),
					)}
				</div>
				<div className="-mt-px w-0 flex-1 flex justify-end">
					<div
						onClick={() => !!canNextPage && onNextClicked()}
						className={classNames(
							"border-t-2 border-transparent pt-4 pe-2 inline-flex items-center text-sm font-medium text-gray-500",
							!!canNextPage
								? "cursor-pointer hover:text-gray-700 hover:border-gray-300"
								: "opacity-40",
						)}
					>
						הבא
						<ArrowNarrowLeftIcon
							className="ms-3 h-5 w-5 text-gray-400"
							aria-hidden="true"
						/>
					</div>
				</div>
			</nav>
			<div className="bg-gray-50 border-t border-gray-200 py-3 sm:px-0 select-none group">
				<div className="text-center text-sm text-gray-400 group-hover:text-gray-500">
					הצג
					<select
						className="mx-1.5 w-[60px] pr-2 pl-7 py-0.5 text-sm border-gray-300 focus:outline-none focus:ring-jane-500 focus:border-jane-500 rounded-md cursor-pointer"
						value={pageSize}
						onChange={ev => pageSizeChanged(ev.target.value)}
					>
						{pageSizes.map(ps => (
							<option key={ps} value={ps}>
								{ps}
							</option>
						))}
					</select>
					תוצאות בעמוד
				</div>
			</div>
		</div>
	);
};

import React, { useEffect, useLayoutEffect, useState } from "react";
import { MapIcon, ViewListIcon } from "@heroicons/react/outline";
import { useRouter } from "next/router";
import { scrollPositionAtom } from "components/jotai/scrollPositionAtom";
import { useAtom } from "jotai";

const MapTableIcon = ({ isTableView = true, setIsTableView, className }) => {
	const [scrollPosition, setScrollPosition] = useAtom(scrollPositionAtom);

	const handleChangeView = () => {
		if (typeof setIsTableView === "function") {
			setIsTableView(prev => !prev);

			// const url = new URL(window.location);
			// if (isTableView) {
			// 	url.searchParams.set("map", "true");
			// } else {
			// 	url.searchParams.delete("map");
			// }
			// window.history.pushState({}, '', url);
		}

		try {
			setScrollPosition(window.scrollY);
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<span
			className={`leading-none rounded cursor-pointer flex flex-col items-center ${className}`}
			onClick={() => handleChangeView()}
		>
			{!isTableView ? (
				<ViewListIcon className="h-6 w-6 p-0 m-0 text-jane-500 leading-none focus:bg-jane-200" />
			) : (
				<MapIcon className="h-6 w-6 p-0 m-0 text-jane-500 leading-none focus:bg-jane-200" />
			)}
		</span>
	);
};

export default MapTableIcon;

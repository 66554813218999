import React from "react";
import StoresQuickFilterButton from "./StoresQuickFilterButton";
import { LocationMarkerIcon } from "@heroicons/react/outline";
import { LocationMarkerIcon as LocationMarkerIconSolid } from "@heroicons/react/solid";

function StoresLocationFilter({
	isFilterActive,
	className,
	setIsDrawerOpen,
	isDrawerOpen,
	withCurrentData,
	disabled = false,
	tbl,
}) {
	const resetLocationFilter = () => {
		const params = {
			latitude: null,
			longitude: null,
		};
		if (withCurrentData) {
			params = {
				...withCurrentData,
				latitude: null,
				longitude: null,
			};
		}
		tbl.changeAddtionalParams(params);
	};

	const handleFilterClick = () => {
		if (isFilterActive) {
			resetLocationFilter();
		} else {
			setIsDrawerOpen(prev => !prev);
		}
	};

	return (
		<StoresQuickFilterButton
			isActive={isFilterActive}
			className={className}
			label="קרוב אליי"
			activeIcon={<LocationMarkerIconSolid />}
			inactiveIcon={<LocationMarkerIcon />}
			onClick={handleFilterClick}
			disabled={disabled}
		/>
	);
}

export default StoresLocationFilter;

import React, { useContext, useEffect, useState } from "react";
import { AnalyticsContext } from "../contexts/AnalyticsProvider";
import FilterDisclosure from "./FilterDisclosure";
import PriceRange from "./PriceRange";
import { classNames, toastMessage } from "util/common";
import { Button } from "components/form/Button";
import FilterTags from "./FilterTags";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import FilterToggle from "./FilterToggle";
import useWindowDimensions from "components/hooks/useWindowDimensions";

function TableFiltersWrapper({
	filters = [],
	values = [],
	onChange = async (values, condition = "in") => {},
	isOpen = false,
	setIsOpen,
	isSingleStore = false,
}) {
	const { collectAnalytics } = useContext(AnalyticsContext);
	//Property for price range filter based on table we use it on
	const priceProperty = isSingleStore ? "store_price" : "is_in_price_range";
	const { width, height } = useWindowDimensions();
	const [loading, setLoading] = useState(false);

	const filterChanged = async (f, selectedValues) => {
		setLoading(true);
		collectAnalytics("table_filter", {
			filter_type: f.property,
			filter_value: selectedValues, // take the last selected item
		});

		let newValues;
		const existingVal = values.find(v => v.property === f.property);

		if (f.type === "toggle") {
			if (selectedValues[0] === "True") {
				newValues = [
					...values,
					{
						property: f.property,
						value: selectedValues,
						labels: [f.options[0].label],
						condition: "in",
					},
				];
				if (f.property === "in_stock") {
					toastMessage("הצגת מוצרים במלאי", "success");
				}
			} else {
				const filteredValues = values.filter(
					filter => filter.property !== f.property,
				);
				newValues = filteredValues;
				// here f still holds current filters
				if (f.property === "in_stock") {
					toastMessage("הצגת כל המוצרים", "success");
				}
			}
		} else if (f.property === priceProperty) {
			// Remove other price range filters
			const filteredValues = values.filter(
				filter => filter.property !== f.property,
			);

			if (selectedValues[0][0] == 0 && selectedValues[0][1] == 700) {
				//If user selected whole range don't apply filter
				newValues = filteredValues;
			} else {
				// Add the new filter
				newValues = [
					...filteredValues,
					{
						property: f.property,
						value: selectedValues,
						labels: f.label, // Assuming f.label contains the label for the selected values
						condition: "in_between",
					},
				];
			}
		} else if (!!existingVal) {
			if (!!selectedValues.length) {
				const labels = selectedValues.map(
					sv => f.options.find(o => o.value === sv)?.label,
				);
				newValues = values.map(v =>
					v.property === f.property
						? { ...v, value: selectedValues, labels: labels }
						: v,
				);
			} else {
				newValues = values.filter(v => v.property !== f.property);
			}
		} else {
			if (!!selectedValues.length) {
				const labels = selectedValues.map(
					sv => f.options.find(o => o.value === sv)?.label,
				);
				newValues = [
					...values,
					{
						property: f.property,
						value: selectedValues,
						labels: labels,
						condition: f.condition,
					},
				];
			} else {
				newValues = values.filter(v => v.property !== f.property);
			}
		}
		await onChange(newValues);
		setLoading(false);
	};
	// Disables scroll when mobile menu is open
	useEffect(() => {
		if (
			isOpen &&
			typeof window != "undefined" &&
			window.document &&
			window.innerWidth < 910
		) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "unset";
		}
		return () => {
			document.body.style.overflow = "unset";
		};
	}, [isOpen]);

	const handleWindowResize = () => {
		if (width < 920 && isOpen) {
			setIsOpen(false);
		}
	};

	// We need this useEffect to close and open menu when user resizes the window
	useEffect(() => {
		handleWindowResize();
	}, [width]);

	return (
		<>
			{isOpen && (
				<div
					onClick={() => setIsOpen(false)}
					className="fixed inset-0 bg-jane-600 opacity-40 z-[997] lmd:hidden"
				></div>
			)}
			<div
				className={classNames(
					isOpen ? "translate-x-0 lmd:block" : "translate-x-full lmd:hidden",
					"transition-transform bg-white rounded-md shadow-sm min-w-[200px]", // Basic styles
					"fixed top-0 right-0 bottom-0 z-[998] w-[315px]", // Mobile menu styles
					"lmd:translate-x-0 lmd:z-auto lmd:w-auto lmd:h-fit lmd:static", // Desktop styles
				)}
			>
				<div className="flex sticky top-0 right-0 left-0  px-4 z-50 bg-white lmd:hidden justify-between w-full items-center py-4 border-b text-jane-900">
					<p className="font-bold text-xl">לסנן</p>
					<Button primary onClick={() => setIsOpen(false)}>
						לתוצאות
						<ArrowLeftIcon className="w-5 h-4" />
					</Button>
				</div>

				<div className="flex flex-col gap-2 px-4 h-[calc(100%-61px)]  pb-20 box-border overflow-y-auto custom-scroll lmd:px-2 lmd:mt-0 lmd:pb-4">
					<div className="lmd:hidden">
						<FilterTags
							filterValues={values}
							isQuickFilter={false}
							isSingleStore={isSingleStore}
							onFiltersChanged={onChange}
						/>
					</div>

					{filters.some(f => f.property === priceProperty) && isSingleStore && (
						<PriceRange
							max={700}
							min={0}
							values={
								values.find(v => v.property === priceProperty)?.value || []
							}
							onSubmit={value =>
								filterChanged(
									{
										name: "טווח מחירים",
										property: priceProperty,
										placeholder: "טווח מחירים",
										condition: "in_between",
										value: [value],
										label: [`₪${value[1]} - ₪${value[0]}`],
									},
									[value],
								)
							}
						/>
					)}

					{filters
						.filter(f => !!f.property)
						.map((f, i) => (
							<div
								key={i}
								className={`${
									filters.length > 2 ? "col-span-4 sm:col-span-6" : "col-span-4"
								} lmd:col-span-2`}
							>
								<>
									{f.type === "select" && (
										<>
											{i > 1 && (
												<div className="w-full h-[1px] bg-jane-200"></div>
											)}
											<FilterDisclosure
												label={f.placeholder}
												options={f.options}
												values={
													values.find(v => v.property === f.property)?.value ||
													[]
												}
												onChange={selectedVals => {
													filterChanged(f, selectedVals);
												}}
												disabled={loading}
												showNum={f.showNum}
												isSearchableFilter={f.isSearchableFilter}
												isMulti={f.isMulti}
												defaultOpen={f.defaultOpen}
												filtersOpen={isOpen}
											/>
										</>
									)}
									{f.type === "toggle" && (
										<FilterToggle
											label={f.placeholder}
											icon={f.icon}
											onToggle={value => filterChanged(f, [value])}
											values={
												values.find(v => v.property === f.property)?.value || []
											}
										/>
									)}
								</>
							</div>
						))}
				</div>
			</div>
		</>
	);
}

export default TableFiltersWrapper;

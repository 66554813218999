import React from "react";
import { classNames } from "util/common";

function TableContainerDrawer({ children, isOpen, onClose, className }) {
	return (
		<div
			className={classNames(
				"absolute top-0 pointer-events-none rounded-md w-full",
				className,
			)}
		>
			{/* Overlay */}
			{isOpen && (
				<div
					className="absolute pointer-events-auto inset-0 h-full bg-jane-200 bg-opacity-70 backdrop-blur-[2px]"
					onClick={onClose}
				/>
			)}

			{/* Drawer */}
			<div
				className={`absolute top-0 left-0 right-0 z-40 w-full pointer-events-auto rounded-b-lg rounded-t-md bg-white transition-transform transform ${
					isOpen ? "translate-y-0" : "-translate-y-full"
				}`}
			>
				{children}
			</div>
		</div>
	);
}

export default TableContainerDrawer;

import { AdjustmentsIcon } from "@heroicons/react/outline";
import { Button } from "components/form/Button";
import React from "react";
import { classNames } from "util/common";

function FiltersButton({
	showFilters = false,
	toggleFilters = () => {},
	filterValues = [],
	className = "",
}) {
	const totalLength = filterValues.reduce((acc, filter) => {
		// Ensure the filter has a "value" property and it is an array
		if (
			filter &&
			filter.property !== "family" &&
			filter.property !== "product_type" &&
			filter.property !== "category" &&
			filter.value &&
			Array.isArray(filter.value)
		) {
			// Add the length of the "value" array to the accumulator
			return acc + filter.value.length;
		} else {
			// If the object doesn't have the expected structure, return the accumulator unchanged
			return acc;
		}
	}, 0);
	return (
		<Button
			onClick={() => toggleFilters()}
			className={classNames(
				"relative px-1",
				showFilters && "bg-jane-100 hover:bg-jane-200",
				className,
			)}
		>
			{!!totalLength && (
				<span className="text-xs w-4 h-4 flex justify-center items-center bg-jane-600 border-[1px] border-white text-white p-1 rounded-full absolute top-[-20%] left-[-12%] z-[41]">
					{totalLength}
				</span>
			)}
			<span className="flex items-center gap-1 px-1 text-sm sm:text-base text-jane-600">
				<AdjustmentsIcon className="w-4 h-4" />
				סינון
			</span>
		</Button>
	);
}

export default FiltersButton;

import { useMediaQuery } from "react-responsive";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "tailwind.config.js";

const fullConfig = resolveConfig(tailwindConfig);

export function useResponsive() {
	const s = fullConfig.theme.screens;
	const is3Xl = useMediaQuery({ query: `(min-width: ${s["3xl"]})` });
	const is2Xl = useMediaQuery({ query: `(min-width: ${s["2xl"]})` });
	const isXl = useMediaQuery({ query: `(min-width: ${s.xl})` });
	const isLg = useMediaQuery({ query: `(min-width: ${s.lg})` });
	const isMd = useMediaQuery({ query: `(min-width: ${s.md})` });
	const isSm = useMediaQuery({ query: `(min-width: ${s.sm})` });
	const isXs = useMediaQuery({ query: `(min-width: ${s.xs})` });
	const is2XS = useMediaQuery({ query: `(min-width: ${s["2xs"]})` });

	const getCurrentBreakpoint = () => {
		if (!!is3Xl) {
			return "3xl";
		} else if (!!is2Xl) {
			return "wxl";
		} else if (!!isXl) {
			return "xl";
		} else if (!!isLg) {
			return "lg";
		} else if (!!isMd) {
			return "md";
		} else if (!!isSm) {
			return "sm";
		} else if (!!isXs) {
			return "xs";
		} else if (!!is2XS) {
			return "2xs";
		} else {
			return null;
		}
	};

	const is = bp => {
		switch (bp) {
			case "3xl":
				return !!is3Xl;
			case "2xl":
				return !!is2Xl;
			case "xl":
				return !!isXl;
			case "lg":
				return !!isLg;
			case "md":
				return !!isMd;
			case "sm":
				return !!isSm;
			case "xs":
				return !!isXs;
			case "2xs":
				return !!is2XS;
			default:
				return false;
		}
	};

	const breakpoint = getCurrentBreakpoint();
	return {
		breakpoint,
		is,
	};
}

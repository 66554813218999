import React, { useContext, useEffect, useState } from "react";
import {
	classNames,
	getFiltersAndSearchQueryFromUrl,
	toastMessage,
} from "util/common";
import {
	GiftIcon as GiftIconOutline,
	TruckIcon as TruckIconOutline,
	HomeIcon as HomeIconOutline,
} from "@heroicons/react/outline";
import {
	GiftIcon as GiftIconSolid,
	TruckIcon as TruckIconSolid,
	HomeIcon as HomeIconSolid,
} from "@heroicons/react/solid";
import { Button } from "components/form/Button";
import { useRouter } from "next/router";
import StoresQuickFilterButton from "./StoresQuickFilterButton";
import { CacheContext } from "components/contexts/CacheContext";

const getQuickStoreFilter = isSingleProduct => ({
	discount: {
		property: "is_discount_exists",
		labels: ["מבצעים"],
		label: "מבצעים",
		inactiveIcon: <GiftIconOutline />,
		activeIcon: <GiftIconSolid />,
		activeMessage: "הצגה של בתי מרקחת עם מבצעים",
		inactiveMessage: "הצגת כל בתי המרקחת",
	},
	open: {
		property: isSingleProduct ? "store__is_active" : "is_active",
		labels: ["חנויות פתוחות"],
		label: "פתוח כעת",
		inactiveIcon: <HomeIconOutline />,
		activeIcon: <HomeIconSolid />,
		activeMessage: "הצגה של בתי מרקחת פתוחים",
		inactiveMessage: "הצגת כל בתי המרקחת",
	},
});

function StoresQuickFilter({
	tbl,
	className,
	filterType,
	isSingleProduct,
	disabled = false,
}) {
	const {
		property,
		labels,
		label,
		inactiveIcon,
		activeIcon,
		activeMessage,
		inactiveMessage,
	} = getQuickStoreFilter(isSingleProduct)[filterType];

	const router = useRouter();
	const cache = useContext(CacheContext);
	const [isFilterActive, setIsFilterActive] = useState(false);

	const handleFilterClick = isActive => {
		if (isActive) {
			tbl.removeFilter({
				property: property,
				value: ["True"],
				labels: labels,
			});
		} else {
			tbl.addAdditionalFilters({
				property: property,
				value: ["True"],
				condition: "in",
				labels: labels,
			});
		}

		setIsFilterActive(!isActive);

		toastMessage(isActive ? inactiveMessage : activeMessage, "info");
	};

	useEffect(() => {
		const { filters } = getFiltersAndSearchQueryFromUrl(router, cache);
		if (filters?.length) {
			filters.forEach(filter => {
				if (filter.property === property) {
					setIsFilterActive(filter.value[0] === "True");
				}
			});
		}
	}, [router, tbl.ready, property]);

	return (
		<StoresQuickFilterButton
			isActive={isFilterActive}
			className={className}
			label={label}
			activeIcon={activeIcon}
			inactiveIcon={inactiveIcon}
			onClick={handleFilterClick}
			disabled={disabled}
		/>
	);
}
export default StoresQuickFilter;

import { Toggle } from "components/form/Toggle";
import React from "react";

function FilterToggle({ onToggle, values, label, icon }) {
	return (
		<div className="flex gap-2 justify-between items-center font-[600]">
			<span className="flex gap-1 items-center">
				{!!icon && icon}
				{label}
			</span>
			<Toggle
				onToggle={e => {
					e.target.checked ? onToggle("True") : onToggle(false);
				}}
				checked={values[0] === "True" ? true : false}
			/>
		</div>
	);
}

export default FilterToggle;

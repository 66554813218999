import { Button } from "components/form/Button";
import { StoresUpdateTime } from "components/stores/StoresUpdateTime";
import { FiltersBar } from "components/tables/FiltersBar";
import { DebounceInput } from "components/tables/server-table/DebounceInput";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { classNames, toastMessage } from "util/common";
import { useIsInIframe } from "components/hooks/useIsInIframe";
import { FaBell } from "react-icons/fa";
import GoToJaneModal from "components/modals/ProductNotifications/GoToJaneModal";
import StoreDidNotUpdateMessage from "components/ui/StoreDidNotUpdateMessage";
import FilterTags from "components/filters/FilterTags";
import MapTableIcon from "components/maps/utils/MapTableIcon";
import FiltersButton from "components/ui/FiltersButton";
import { CacheContext } from "components/contexts/CacheContext";
import useUserAddresses from "components/hooks/useUserAddresses";
import StoresQuickFilter from "components/ui/StoresQuickFilter";
import StoresDeliveryFilter from "components/ui/StoresDeliveryFilter";
import StoresLocationFilter from "components/ui/StoresLocationFilter";
import DeliveryDrawer from "components/ui/drawers/DeliveryDrawer";
import LocationDrawer from "components/ui/drawers/LocationDrawer";
import LayoutButton from "components/ui/LayoutButton";

const SingleProductContainer = ({
	prependStoreContent,
	hideOnScroll,
	prependContent,
	tbl,
	title,
	itemsCountLabel,
	subtitle,
	prependStoreSortByContent,
	withGridLayout,
	searchable,
	onSearchBounce,
	hasFilters,
	showFilters,
	setShowFilters,
	filters = [],
	filterValues,
	filtersChanged,
	subContent,
	is48HoursPast,
	isHomeWidget,
	isWithDiscountsFilter,
	isWithShippingFilter,
	isWithOpenFilter,
	customButtons,
	itemId,
	children,
	searchQuery,
	isWithLocationButton,
	setIsTableView,
	isTableView,
	searchPlaceholder,
}) => {
	const isInIframe = useIsInIframe();
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const { allCities } = useContext(CacheContext);
	const [isLocationDrawerOpen, setIsLocationDrawerOpen] = useState(false);

	const { userAddresses = [] } = useUserAddresses();

	const formattedAddresses = userAddresses.map(address => ({
		label: address.city_heb_name,
		value: address.city_id,
		isHomeAddress: true,
	}));

	const formattedAllCities = allCities.map(c => ({
		label: c.heb_name,
		value: c.id,
	}));

	const initialOptions = [...formattedAddresses];

	if (formattedAddresses.length > 0) {
		initialOptions.push({
			id: "-1",
			label: "------------",
			value: undefined,
			isDisabled: true,
		});
	}

	const options = [...initialOptions, ...formattedAllCities].filter(
		(value, index, self) =>
			index ===
			self.findIndex(t => t.label === value.label && t.value === value.value),
	);

	const deliveryFilter = useMemo(
		() => [
			{
				name: "עיר למשלוח",
				property: "store__shipping_city_restrictions__city_id",
				placeholder: "ערים",
				isSearchableFilter: true,
				type: "select",
				isMulti: true,
				condition: "in",
				options: options,
			},
		],
		[options],
	);

	useEffect(() => {
		if (
			!filterValues.some(
				f => f.property === "store__shipping_city_restrictions__city_id",
			) &&
			filterValues.some(f => f.property === "store__today_or_tomorrow_shipping")
		) {
			const newValues = filterValues.filter(
				f => f.property !== "store__today_or_tomorrow_shipping",
			);
			filtersChanged(newValues);
		}
	}, [
		filterValues.some(
			f => f.property === "store__shipping_city_restrictions__city_id",
		),
	]);

	return (
		<div className="relative">
			{/* DRAWER OVERLAY */}
			{(isDrawerOpen || isLocationDrawerOpen) && (
				<div
					onClick={() => {
						setIsDrawerOpen(false);
						setIsLocationDrawerOpen(false);
					}}
					className="absolute  z-40 inset-0 w-full h-full bg-jane-200 bg-opacity-70 backdrop-blur-[2px]"
				/>
			)}
			<div className="absolute rounded-md inset-0 w-full h-full overflow-hidden">
				<DeliveryDrawer
					deliveryFilter={deliveryFilter}
					filterValues={filterValues}
					filtersChanged={filtersChanged}
					isDrawerOpen={isDrawerOpen}
					setIsDrawerOpen={setIsDrawerOpen}
					todayOrTomorrowProperty="store__today_or_tomorrow_shipping"
					shippingProperty="store__shipping_city_restrictions__city_id"
				/>
				<LocationDrawer
					isDrawerOpen={isLocationDrawerOpen}
					withCurrentData={tbl?.addtionalParams}
					setIsDrawerOpen={setIsLocationDrawerOpen}
					tbl={tbl}
				/>
			</div>
			<div className="bg-white shadow rounded-md">
				{prependStoreContent}
				{hideOnScroll && prependContent}
				<div
					className={classNames(
						"bg-white rounded-t-md",
						withGridLayout ? "z-40" : "sticky z-30",
						isHomeWidget ? "top-0" : "top-[55px]",
					)}
				>
					{!hideOnScroll && prependContent}
					<div className="relative p-2 flex sm:items-center items-start sm:flex-row justify-between flex-wrap w-full">
						<div className="w-full flex items-center flex-col gap-2">
							<div className="flex justify-between w-full gap-2 items-center">
								<div className="flex whitespace-nowrap">
									<h2 className="flex items-center text-base font-semibold text-gray-800 ml-2">
										{title}
										{!!tbl.ready && !tbl.loading ? (
											<span className="mr-1.5 text-gray-500 font-medium text-sm">
												{itemsCountLabel}
											</span>
										) : (
											<div className="min-w-[30px] mr-2 h-[16px] bg-jane-200 rounded-md animate-pulse" />
										)}
										<br />
									</h2>
									{isInIframe && (
										<GoToJaneModal
											productId={itemId}
											activator={
												<Button className="h-[33px] mt-0.5">
													<FaBell className="text-[#64748b] h-full" />
												</Button>
											}
										/>
									)}
									{tbl?.lastUpdateAt ? (
										<div className="flex items-center">
											<StoresUpdateTime lastUpdateAt={tbl.lastUpdateAt} />
										</div>
									) : null}
								</div>

								{!!tbl.ready ? (
									<MapTableIcon
										className="ml-1"
										isTableView={isTableView}
										setIsTableView={setIsTableView}
									/>
								) : (
									<div className="min-w-[24px] h-[24px] bg-jane-200 rounded-md animate-pulse" />
								)}
							</div>
							<div className="grid grid-cols-4 gap-2 w-full">
								{!!searchable && (
									<DebounceInput
										disabled={!tbl.ready}
										placeholder={searchPlaceholder}
										onBounce={onSearchBounce}
										className={`w-full col-span-3 md:max-w-[400px] xl:max-w-none`}
										searchQuery={searchQuery}
									/>
								)}
								<FiltersButton
									showFilters={showFilters}
									toggleFilters={() => setShowFilters(!showFilters)}
									className="w-full md:w-fit xl:w-full mr-auto"
								/>
							</div>
							{showFilters && (
								<div className="flex flex-col md:flex-row xl:flex-col gap-2 items-center relative rounded-md w-full">
									<div className="flex gap-2 w-full">
										{!!isWithLocationButton && (
											<StoresLocationFilter
												isDrawerOpen={isLocationDrawerOpen}
												setIsDrawerOpen={setIsLocationDrawerOpen}
												isFilterActive={
													tbl?.addtionalParams?.latitude ||
													tbl?.addtionalParams?.longitude
												}
												tbl={tbl}
												withCurrentData={tbl?.addtionalParams}
												className={classNames(
													"w-full",
													!isTableView && "hidden",
												)}
												disabled={!tbl.ready || tbl.loading}
											/>
										)}
										{!!customButtons && customButtons}
										{isWithOpenFilter && (
											<StoresQuickFilter
												tbl={tbl}
												className="w-full"
												filterType="open"
												isSingleProduct={true}
												disabled={!tbl.ready || tbl.loading}
											/>
										)}
										{isWithShippingFilter && (
											<StoresDeliveryFilter
												isDrawerOpen={isDrawerOpen}
												setIsDrawerOpen={setIsDrawerOpen}
												className="w-full"
												isFilterActive={filterValues.some(
													f =>
														f.property ===
														"store__shipping_city_restrictions__city_id",
												)}
												disabled={!tbl.ready || tbl.loading}
											/>
										)}
										{isWithDiscountsFilter && (
											<StoresQuickFilter
												tbl={tbl}
												className="w-full"
												filterType="discount"
												isSingleProduct={true}
												disabled={!tbl.ready || tbl.loading}
											/>
										)}
									</div>
									<div className="w-full self-center">
										<FiltersBar
											filters={filters}
											values={filterValues}
											onChange={filtersChanged}
											isSingleProductContainer={true}
											disabled={!tbl.ready || tbl.loading}
										/>
									</div>
								</div>
							)}
							{(!!subtitle || !!prependStoreSortByContent) && (
								<div className="flex">
									{!!subtitle && (
										<p className="mt-1 text-sm text-gray-500">{subtitle}</p>
									)}
									{!!prependStoreSortByContent && (
										<span className="z-40">{prependStoreSortByContent}</span>
									)}
								</div>
							)}
							<LayoutButton
								layoutOptions={tbl.layoutOptions}
								layout={tbl.layout}
								changeLayout={tbl.changeLayout}
								loading={!tbl.ready}
							/>
						</div>
					</div>

					{!!hasFilters && !!showFilters && !isDrawerOpen && (
						<FilterTags
							filterValues={filterValues}
							isQuickFilter={false}
							isSingleStore={false}
							onFiltersChanged={filtersChanged}
							isSingleProduct={true}
							disabled={!tbl.ready || tbl.loading}
						/>
					)}
					{!!subContent && <div className="pb-2">{subContent}</div>}
				</div>
				<div className="relative p-1 block ">
					{!!is48HoursPast ? <StoreDidNotUpdateMessage /> : children}
				</div>
			</div>
		</div>
	);
};

export default SingleProductContainer;

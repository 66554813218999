import React, { useCallback, useEffect, useRef } from "react";

const DoubleRangeSlider = ({
	min,
	max,
	step,
	minValue,
	setMinValue,
	maxValue,
	setMaxValue,
	onSubmit,
}) => {
	const range = useRef(null);

	// Convert to percentage
	const getPercent = useCallback(
		value => Math.round(((value - min) / (max - min)) * 100),
		[min, max],
	);

	const roundToNearestStep = (value, step) => {
		return Math.round(value / step) * step;
	};

	// Set width of the range
	useEffect(() => {
		const minPercent = getPercent(roundToNearestStep(minValue, step));
		const maxPercent = getPercent(roundToNearestStep(maxValue, step));

		if (range.current) {
			range.current.style.right = `${minPercent}%`;
			range.current.style.width = `${maxPercent - minPercent}%`;
		}
	}, [minValue, maxValue, getPercent, step]);

	return (
		<div className="flex items-center justify-center ltr py-4 relative">
			<input
				type="range"
				min={min}
				max={max}
				step={step}
				value={minValue}
				onChange={event => {
					setMinValue(Math.min(Number(event.target.value), maxValue - step));
				}}
				onTouchEnd={() => onSubmit([minValue, maxValue])}
				onMouseUp={() => onSubmit([minValue, maxValue])}
				className="pointer-events-none absolute h-0 w-full outline-none z-[3] double-range-thumb"
				style={{ zIndex: minValue > max - 100 && "5" }}
			/>
			<input
				type="range"
				min={min}
				max={max}
				step={step}
				value={maxValue}
				onChange={event => {
					setMaxValue(Math.max(Number(event.target.value), minValue + step));
				}}
				onTouchEnd={() => onSubmit([minValue, maxValue])}
				onMouseUp={() => onSubmit([minValue, maxValue])}
				className="pointer-events-none absolute h-0 w-full outline-none z-[4] double-range-thumb"
			/>

			<div className="relative w-full">
				<div className="w-full bg-gray-200 z-[1] rounded-md h-[5px] absolute" />
				<div
					ref={range}
					className="z-[2] bg-jane-600 absolute rounded-md h-[5px]"
				/>
			</div>
		</div>
	);
};

export default DoubleRangeSlider;

import { Disclosure, Menu } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import React, { createRef, Fragment, useMemo, useState } from "react";
import {
	PRODUCTS_SORTBY_OPTIONS,
	classNames,
	findTitleByValueProducts,
} from "util/common";
import { BiSortAlt2 } from "react-icons/bi";
import { WBO } from "util/types";
import useWindowDimensions from "components/hooks/useWindowDimensions";
import { Button } from "components/form/Button";

const ProductsSortBy = ({
	tbl,
	className,
	placeholder = "סידור לפי בחירת מחדל",
	customSortByOption = {},
}) => {
	const options = [...PRODUCTS_SORTBY_OPTIONS];

	if (!!customSortByOption) {
		options.push(customSortByOption);
	}

	const [productsSortBy, setProductsSortBy] = useState("-");

	const refs = useMemo(() => {
		return (
			options.map(opt => {
				return createRef();
			}) ?? []
		);
	}, [options]);

	const handleSortChange = newSortOption => {
		const title = findTitleByValueProducts(newSortOption);
		if (!title && !title?.label) {
			setProductsSortBy("-");
		} else {
			setProductsSortBy(title);
		}
		tbl.changeSortBy([newSortOption]);
	};

	function handleClosingOthers(id) {
		const otherRefs = refs.filter(ref => {
			return ref.current?.getAttribute("data-id") !== id;
		});

		otherRefs.forEach(ref => {
			const isOpen = ref.current?.getAttribute("data-open") === "true";

			if (isOpen) {
				ref.current?.click();
			}
		});
	}

	const { width } = useWindowDimensions();

	const defaultSortByOption = options.find(o => o.value === "-");

	const displayFilterText = () => {
		if (productsSortBy === "סדר לפי") {
			return width >= WBO["sm"]
				? defaultSortByOption.longLabel
				: defaultSortByOption.label;
		}

		return productsSortBy;
	};
	return (
		<Menu as="div" className={`relative ${className}`}>
			<Menu.Button as={Fragment}>
				<Button className={`${className}`}>
					<span className="inline-flex text-sm sm:text-base w-full gap-1 text-jane-800 justify-center items-center">
						<BiSortAlt2 className="w-4 h-4 " />
						{!!productsSortBy && productsSortBy !== "-"
							? displayFilterText()
							: placeholder}
					</span>
				</Button>
			</Menu.Button>
			<Menu.Items>
				<div className="absolute top-full shadow mt-1 right-0 bg-white w-40 rounded-md border">
					{options.map((opt, i) => {
						if (opt.options) {
							return (
								<Disclosure key={i}>
									{({ open: disclosureOpen }) => {
										return (
											<>
												<Disclosure.Button
													ref={refs[i]}
													data-id={opt.label}
													data-open={disclosureOpen}
													className="py-2 px-1 flex items-center justify-between w-full hover:bg-[#f9f9f9] transition-all cursor-pointer"
													onClick={() => handleClosingOthers(opt.label)}
												>
													<span className="text-sm">{opt.label}</span>
													<span>
														{disclosureOpen ? (
															<ChevronUpIcon className="w-2 h-2" />
														) : (
															<ChevronDownIcon className="w-2 h-2" />
														)}
													</span>
												</Disclosure.Button>
												<Disclosure.Panel className="text-gray-500">
													{opt.options.map((opt, j) => {
														return (
															<Menu.Item key={j}>
																{({ active }) => (
																	<div
																		className={classNames(
																			"py-2 px-1 text-sm hover:bg-[#f9f9f9] transition-all cursor-pointer",
																			active ? "bg-blue-500" : "text-black",
																		)}
																		onClick={() => handleSortChange(opt.value)}
																	>
																		{opt.longLabel ?? opt.label}
																	</div>
																)}
															</Menu.Item>
														);
													})}
												</Disclosure.Panel>
											</>
										);
									}}
								</Disclosure>
							);
						} else {
							return (
								<Menu.Item key={i}>
									{({ active }) => (
										<div
											className={classNames(
												"py-2 px-1 text-sm hover:bg-[#f9f9f9] transition-all cursor-pointer",
												active ? "bg-blue-500" : "text-black",
											)}
											onClick={() => handleSortChange(opt.value)}
										>
											{opt.longLabel ?? opt.label}
										</div>
									)}
								</Menu.Item>
							);
						}
					})}
				</div>
			</Menu.Items>
		</Menu>
	);
};

export default ProductsSortBy;

import React, { useCallback, useEffect, useState } from "react";
import DoubleRangeSlider from "../form/DoubleRangeSlider";

//Define step of the range sliders
const STEP = 1;

function PriceRange({ max, min = 0, values, onSubmit }) {
	const [minValue, setMinValue] = useState(min);
	const [maxValue, setMaxValue] = useState(max);

	//If filter values changed from outside of component:
	//filters were cleared or values were set by search query -
	//then use updated values
	useEffect(() => {
		if (values && values?.length !== 0) {
			setMinValue(values[0][0]);
			setMaxValue(values[0][1]);
		} else {
			setMinValue(min);
			setMaxValue(max);
		}
	}, [values, min, max]);

	//Round value and submit filter change
	const handleBlur = useCallback(
		(value, isMin) => {
			const newValue = isMin
				? Math.floor(Math.min(value, maxValue - STEP) / STEP) * STEP
				: Math.ceil(Math.max(value, minValue + STEP) / STEP) * STEP;
			isMin ? setMinValue(newValue) : setMaxValue(newValue);

			onSubmit(isMin ? [newValue, maxValue] : [minValue, newValue]);
		},
		[minValue, maxValue, onSubmit],
	);

	//Handle Enter key press
	const handleKeyPress = (e, isMin) => {
		if (e.key === "Enter") {
			e.target.blur();
			handleBlur(Number(e.target.value), isMin);
		}
	};

	return (
		<div>
			<div className="flex gap-2 justify-between items-center mt-2">
				<div className="flex items-center lmd:justify-between w-full gap-4">
					<span className="relative">
						<span className="pointer-events-none ml-1">מ-</span>
						<input
							type="number"
							value={minValue}
							max={maxValue}
							min={min}
							onChange={e =>
								e.target.value >= maxValue
									? setMinValue(maxValue - STEP)
									: setMinValue(Number(e.target.value).toString())
							} //Handle values change (not higher than maxVale)
							onBlur={e => handleBlur(Number(e.target.value), true)} //Handle priceRange submit
							onKeyDown={e => handleKeyPress(e, true)} //Handle enter key press
							onClick={e => e.target.select()} //Select all
							className="rounded-md w-[55px] py-1 px-2"
						/>
						<span className="absolute left-[8px] top-0 bottom-0 flex items-center pointer-events-none text-jane-400">
							₪
						</span>
					</span>

					<span className="relative">
						<span className="pointer-events-none  ml-1">עד-</span>
						<input
							type="number"
							value={maxValue}
							max={max}
							min={minValue}
							onChange={e =>
								e.target.value >= max
									? setMaxValue(max)
									: e.target.value <= min && e.target.value !== ""
									? setMaxValue(minValue + STEP)
									: setMaxValue(Number(e.target.value).toString())
							} //Handle values change (not lower than minVale)
							onBlur={e => handleBlur(Number(e.target.value), false)} //Handle priceRange submit
							onKeyDown={e => handleKeyPress(e, false)} //Handle enter key press
							onClick={e => e.target.select()} //Select all
							className="rounded-md w-[55px] py-1 px-2"
						/>
						<span className="absolute left-[8px] top-0 bottom-0 flex items-center pointer-events-none text-jane-400">
							₪
						</span>
					</span>
				</div>
			</div>
			<DoubleRangeSlider
				min={min}
				max={max}
				step={STEP}
				minValue={minValue}
				maxValue={maxValue}
				setMinValue={setMinValue}
				setMaxValue={setMaxValue}
				onSubmit={onSubmit}
			/>
		</div>
	);
}

export default PriceRange;

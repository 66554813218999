import { RichSelect } from "components/form/RichSelect";
import { useContext } from "react";
import { AnalyticsContext } from "../contexts/AnalyticsProvider";
import { classNames } from "util/common";
import useWindowDimensions from "components/hooks/useWindowDimensions";
import { WBO } from "util/types";

export const FiltersBar = ({
	filters = [],
	values = [],
	onChange = (values, condition = "in") => {}, // [ { property: "foo", value: ["one", "two"] }, ... ]
	disabled = false,
}) => {
	const { collectAnalytics } = useContext(AnalyticsContext);
	const { width, height } = useWindowDimensions();
	const filterChanged = (f, selectedValues) => {
		collectAnalytics("table_filter", {
			filter_type: f.property,
			filter_value: selectedValues, // take the last selected item
		});
		let newValues;
		const existingVal = values.find(v => v.property === f.property);
		if (!!existingVal) {
			if (!!selectedValues.length) {
				const labels = selectedValues.map(
					sv => f.options.find(o => o.value === sv)?.label,
				);
				newValues = values.map(v =>
					v.property === f.property
						? { ...v, value: selectedValues, labels: labels }
						: v,
				);
			} else {
				newValues = values.filter(v => v.property !== f.property);
			}
		} else {
			if (!!selectedValues.length) {
				const labels = selectedValues.map(
					sv => f.options.find(o => o.value === sv)?.label,
				);
				newValues = [
					...values,
					{
						property: f.property,
						value: selectedValues,
						labels: labels,
						condition: f.condition,
					},
				];
			} else {
				newValues = values.filter(v => v.property !== f.property);
			}
		}
		onChange(newValues);
	};
	return (
		<div
			className={classNames(
				"grid gap-2 w-full z-30",
				filters.length === 1
					? "grid-cols-1"
					: filters.length % 2 == 0
					? "grid-cols-2"
					: "grid-cols-3",
			)}
		>
			{filters
				.filter(f => !!f.property)
				.map((f, i) => (
					<div key={i} className={classNames("w-full")}>
						<RichSelect
							minWidth={100}
							placeholder={
								<span className={"whitespace-nowrap lg:whitespace-normal"}>
									{f.placeholder || "בחר ..."}
								</span>
							}
							options={f.options || []}
							fontSize={WBO["sm"] < width ? 16 : 14}
							paddingY={WBO["sm"] < width ? 2 : 0}
							value={values.find(v => v.property === f.property)?.value || []}
							onChange={selectedVals => filterChanged(f, selectedVals)}
							className="w-full"
							isSearchableFilter={f.isSearchableFilter}
							isMulti={f.isMulti}
							showNum={f.showNum}
							disabled={disabled}
						/>
					</div>
				))}
		</div>
	);
};

import { FiltersBar } from "components/tables/FiltersBar";
import { DebounceInput } from "components/tables/server-table/DebounceInput";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { classNames, toastMessage } from "util/common";
import FilterTags from "components/filters/FilterTags";
import { useIsInIframe } from "components/hooks/useIsInIframe";
import { CacheContext } from "components/contexts/CacheContext";
import FiltersButton from "components/ui/FiltersButton";
import StoresQuickFilter from "components/ui/StoresQuickFilter";
import useUserAddresses from "components/hooks/useUserAddresses";
import StoresDeliveryFilter from "components/ui/StoresDeliveryFilter";
import DeliveryDrawer from "components/ui/drawers/DeliveryDrawer";
import LocationDrawer from "components/ui/drawers/LocationDrawer";
import StoresLocationFilter from "components/ui/StoresLocationFilter";
import { ShoppingCartIcon } from "@heroicons/react/outline";
import { Button } from "components/form/Button";
import ProductsSortBy from "components/products/ProductsSortBy";
import TableFiltersWrapper from "components/filters/TableFiltersWrapper";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import LayoutButton from "components/ui/LayoutButton";
import { FaSpinner } from "react-icons/fa";

const DesiredCartContainer = ({
	tbl,
	prependStoreContent,
	onSearchBounce,
	hasFilters,
	showFilters,
	setShowFilters,
	filters,
	filterValues,
	filtersChanged,
	children,
	searchQuery,
	searchPlaceholder,
	subContent = null,
	isResults = false,
	toggleResults = () => {},
	productsToCompare = [],
	setProductsToCompare = () => {},
	isCartDrawerOpen = false,
	setIsCartDrawerOpen = () => {},
	availableStoresCount,
	availableStoresCountLoading,
}) => {
	const isInIframe = useIsInIframe();
	const { allCities } = useContext(CacheContext);
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [isLocationDrawerOpen, setIsLocationDrawerOpen] = useState(false);

	const { userAddresses = [] } = useUserAddresses();

	const formattedAddresses = userAddresses.map(address => ({
		label: address.city_heb_name,
		value: address.city_id,
		isHomeAddress: true,
	}));

	const formattedAllCities = allCities.map(c => ({
		label: c.heb_name,
		value: c.id,
	}));

	const initialOptions = [...formattedAddresses];

	if (formattedAddresses.length > 0) {
		initialOptions.push({
			id: "-1",
			label: "------------",
			value: undefined,
			isDisabled: true,
		});
	}

	const options = [...initialOptions, ...formattedAllCities].filter(
		(value, index, self) =>
			index ===
			self.findIndex(t => t.label === value.label && t.value === value.value),
	);

	useEffect(() => {
		if (
			!filterValues.some(
				f => f.property === "shipping_city_restrictions__city_id",
			) &&
			filterValues.some(f => f.property === "today_or_tomorrow_shipping")
		) {
			const newValues = filterValues.filter(
				f => f.property !== "today_or_tomorrow_shipping",
			);
			filtersChanged(newValues);
		}
	}, [
		filterValues.some(
			f => f.property === "shipping_city_restrictions__city_id",
		),
	]);

	const deliveryFilter = useMemo(
		() => [
			{
				name: "עיר למשלוח",
				property: "shipping_city_restrictions__city_id",
				placeholder: "ערים",
				isSearchableFilter: true,
				type: "select",
				isMulti: true,
				condition: "in",
				options: options,
			},
		],
		[options],
	);

	return (
		<div className="relative">
			{/* DRAWER OVERLAY */}
			{(isDrawerOpen || isLocationDrawerOpen) && (
				<div
					onClick={() => {
						setIsDrawerOpen(false);
						setIsLocationDrawerOpen(false);
					}}
					className="absolute z-40 inset-0 w-full h-full bg-jane-200 bg-opacity-70 backdrop-blur-[2px]"
				/>
			)}
			<div className="absolute rounded-md inset-0 w-full h-full overflow-hidden">
				<DeliveryDrawer
					deliveryFilter={deliveryFilter}
					filterValues={filterValues}
					filtersChanged={filtersChanged}
					isDrawerOpen={isDrawerOpen}
					setIsDrawerOpen={setIsDrawerOpen}
				/>
				<LocationDrawer
					isDrawerOpen={isLocationDrawerOpen}
					withCurrentData={tbl?.addtionalParams}
					setIsDrawerOpen={setIsLocationDrawerOpen}
					tbl={tbl}
				/>
			</div>
			<div className="bg-white shadow rounded-md">
				{prependStoreContent}
				<div
					className={classNames(
						"bg-white rounded-t-md",
						`sticky z-30 ${isInIframe ? "top-0" : "top-[55px] lg:top-[60px]"}`,
					)}
				>
					<div
						className={classNames(
							"flex p-2 pb-0 sm:items-center items-start sm:flex-row justify-between flex-wrap w-full gap-2",
						)}
					>
						<div className="flex items-center flex-row justify-between w-full">
							<div className="flex w-full gap-2 items-center">
								<DebounceInput
									placeholder={searchPlaceholder}
									onBounce={onSearchBounce}
									disabled={!tbl.ready}
									className={`w-full  md:min-w-[150px] grow h-full`}
									searchQuery={searchQuery}
								/>
								{isInIframe && (
									<>
										<Button
											className="w-fit relative"
											onClick={() => setIsCartDrawerOpen(!isCartDrawerOpen)}
										>
											<ShoppingCartIcon className="h-5 w-5" />
											<span className="absolute w-4 h-4 flex items-center justify-center bg-jane-200 text-jane-800 text-sm rounded-full -top-1.5 -right-1.5">
												{productsToCompare.length}
											</span>
										</Button>
										<Button
											primary
											className="h-[30px]"
											disabled={
												!isResults &&
												(productsToCompare?.length < 2 ||
													!availableStoresCount ||
													availableStoresCountLoading)
											}
											onClick={() => toggleResults()}
										>
											<span className="flex items-center text-base leading-none gap-1">
												{isResults && (
													<ArrowLeftIcon className="h-4 w-4 rotate-180" />
												)}
												{isResults ? "חזרה" : "השווה"}
												{!isResults && productsToCompare?.length >= 2 && (
													<span
														className={`text-xs whitespace-nowrap ${
															!!availableStoresCount ||
															availableStoresCountLoading
																? "text-white"
																: "text-red-500"
														}`}
													>
														(
														{availableStoresCountLoading ? (
															<FaSpinner className="w-2.5 h-2.5 animate-spin inline" />
														) : (
															availableStoresCount
														)}{" "}
														תוצאות)
													</span>
												)}
												{!isResults && <ArrowLeftIcon className="h-4 w-4" />}
											</span>
										</Button>
									</>
								)}
								<LayoutButton
									layoutOptions={tbl.layoutOptions}
									layout={tbl.layout}
									changeLayout={tbl.changeLayout}
									loading={!tbl.ready}
								/>
							</div>
						</div>
						{!isResults && (
							<div className="flex w-full gap-2">
								<FiltersButton
									toggleFilters={() => setShowFilters(!showFilters)}
									showFilters={showFilters}
									className="w-full"
								/>

								<ProductsSortBy
									tbl={tbl}
									placeholder="סדר לפי"
									className="w-full relative z-40"
								/>
							</div>
						)}
						{isResults && (
							<div className="flex flex-col gap-2 w-full">
								<div className="flex gap-2 w-full">
									<StoresLocationFilter
										isDrawerOpen={isLocationDrawerOpen}
										setIsDrawerOpen={setIsLocationDrawerOpen}
										isFilterActive={
											tbl?.addtionalParams?.latitude ||
											tbl?.addtionalParams?.longitude
										}
										tbl={tbl}
										withCurrentData={tbl?.addtionalParams}
										className={classNames("w-full")}
										disabled={!tbl.ready || tbl.loading}
									/>
									<StoresQuickFilter
										tbl={tbl}
										filterType="open"
										className="w-full"
										disabled={!tbl.ready || tbl.loading}
									/>
									<StoresDeliveryFilter
										isDrawerOpen={isDrawerOpen}
										setIsDrawerOpen={setIsDrawerOpen}
										isFilterActive={filterValues.some(
											f => f.property === "shipping_city_restrictions__city_id",
										)}
										className="w-full"
										disabled={!tbl.ready || tbl.loading}
									/>
									<StoresQuickFilter
										tbl={tbl}
										filterType="discount"
										className="w-full"
										disabled={!tbl.ready || tbl.loading}
									/>
								</div>
								<span className="block md:hidden w-full">
									{hasFilters && showFilters && isResults && (
										<FiltersBar
											filters={filters}
											values={filterValues}
											onChange={filtersChanged}
											isSingleProductContainer={true}
											disabled={!tbl.ready || tbl.loading}
										/>
									)}
								</span>
							</div>
						)}
					</div>
					{subContent}
					{!isDrawerOpen && (
						<div className="mt-1">
							<FilterTags
								filterValues={filterValues}
								isQuickFilter={false}
								isSingleStore={true}
								onFiltersChanged={filtersChanged}
								disabled={!tbl.ready || tbl.loading}
							/>
						</div>
					)}
				</div>
				<div className="py-2 gap-2 flex w-full relative">
					{!!hasFilters && !isResults && (
						<TableFiltersWrapper
							filters={filters}
							values={filterValues}
							onChange={filtersChanged}
							isOpen={showFilters}
							setIsOpen={setShowFilters}
						/>
					)}
					<div className="relative p-1 w-full">{children}</div>
				</div>
			</div>
		</div>
	);
};

export default DesiredCartContainer;

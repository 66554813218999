import { Button } from "components/form/Button";
import React from "react";
import { classNames } from "util/common";

function StoresQuickFilterButton({
	isActive,
	className,
	label,
	activeIcon,
	inactiveIcon,
	onClick,
	disabled = false,
}) {
	return (
		<Button
			disabled={disabled}
			className={classNames(
				"flex flex-col xs:flex-row xs:gap-1 transition-all items-center min-h-[30px] ",
				isActive && "bg-jane-200 hover:bg-jane-300",
				className,
			)}
			onClick={() => {
				onClick(isActive);
			}}
		>
			<span className="w-4 h-4 sm:h-5 sm:w-5 text-jane-500">
				{!!isActive ? activeIcon : inactiveIcon}
			</span>
			<span className="text-jane-600 whitespace-nowrap text-sm sm:text-base ">
				{label}
			</span>
		</Button>
	);
}

export default StoresQuickFilterButton;
